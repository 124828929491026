import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Grid,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MetricIcon } from "./tenantProfile/MonitorMetric";

function MetricStatisticsTable({
  metrics,
  submitAction,
  toggleSelect,
  selectedList,
}) {
  return (
    <Box sx={{ marginTop: 2 }}>
      {Object.entries(metrics).map(([category, metrics]) => (
        <Accordion key={category}>
          {/* Category Accordion */}
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{category}</Typography>
          </AccordionSummary>
          {metrics.map((metric) => {
            // Filter out success tenants
            const filteredTenants = metric.sortedTenants.filter(
              (tenant) => tenant.state !== "success"
            );

            return (
              <Accordion key={metric.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {toggleSelect && (
                  <Grid item>
                    <Checkbox
                      checked={selectedList.includes(metric.id)}
                      onChange={(event) => {
                        event.stopPropagation();
                        toggleSelect(metric.id);
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </Grid>
                )}
                {/* Metric Accordion */}
                  <Typography sx={{ flexGrow: 1, alignContent:"center" }}>{metric.name}</Typography>

                  {/* Right Side Box for MetricState Counts */}
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center", marginX:2 }}>
                    {metric.autofix && <Chip label="autofix" size="small" color="secondary"/>}
                    {metric.remediation && <Chip label="remediation" size="small" color="primary"/>}
                  
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <MetricIcon state="success" />
                    <Typography variant="h6">
                      {metric.successCount || 0}
                    </Typography>
                    <MetricIcon state="warning" />
                    <Typography variant="h6">
                      {metric.warningCount || 0}
                    </Typography>

                    <MetricIcon state="error" />
                    <Typography variant="h6">
                      {metric.errorCount || 0}
                    </Typography>

                    <MetricIcon state="snoozed" />
                    <Typography variant="h6">
                      {metric.snoozedCount || 0}
                    </Typography>
                  </Box>
                </AccordionSummary>

                {/* Expanding Shows Sorted Tenant Table */}
                <AccordionDetails>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tenant ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTenants.map((tenant) => (
                        <TableRow key={tenant.tenantId}>
                          <TableCell>{tenant.tenantId}</TableCell>
                          <TableCell>{tenant.displayName}</TableCell>
                          <TableCell
                            sx={{
                              color:
                                tenant.state === "warning"
                                  ? "orange"
                                  : tenant.state === "error"
                                  ? "red"
                                  : "gray",
                            }}
                          >
                            {tenant.state.toUpperCase()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Accordion>
      ))}
    </Box>
  );
}

export default MetricStatisticsTable;

import { useContext, useCallback } from "react";
import { getEndpointInfo } from "../tools/axiosFunctions";
import { BackofficeContext } from "../tools/BackofficeContext";
import axios from "axios";
export const useApi = () => {
  const { environment, tenantId } = useContext(BackofficeContext);

  const getApi = useCallback(
    async (uri, target = false, signal = false) => {
      const { headers, endpoint } = getEndpointInfo(
        target ? target : environment,
      );
      if (signal) {
        return axios
          .get(`${endpoint}${uri}`, {
            headers: headers,
            signal: signal,
          })
          .then((response) => response)
          .catch((error) => {
            if (error.code === "ERR_CANCELED") {
              throw error;
            } else {
              console.log(error);
            }
          });
      } else {
        return axios
          .get(`${endpoint}${uri}`, { headers: headers })
          .then((response) => response)
          .catch((error) => console.log(error));
      }
    },
    [environment],
  );

  const getApiAsTenant = useCallback(
    async (uri, tenant = false, target = false, signal = false) => {
      const { headers, endpoint } = getEndpointInfo(
        target ? target : environment,
      );
      headers.tenantid = tenant ? tenant : tenantId;
      if (signal) {
        return axios
          .get(`${endpoint}${uri}`, {
            headers: headers,
            signal: signal,
          })
          .then((response) => response)
          .catch((error) => {
            if (error.code === "ERR_CANCELED") {
              throw error;
            } else {
              console.log(error);
            }
          });
      } else {
        return axios
          .get(`${endpoint}${uri}`, { headers: headers })
          .then((response) => response)
          .catch((error) => console.log(error));
      }
    },
    [environment, tenantId],
  );
  const postApi = useCallback(
    async (uri, data, target = false) => {
      const { headers, endpoint } = getEndpointInfo(
        target ? target : environment,
      );
      return axios
        .post(`${endpoint}${uri}`, data, { headers: headers })
        .then((response) => response)
        .catch((error) => console.log(error));
    },
    [environment],
  );

  const postApiAsTenant = useCallback(
    async (uri, data, tenant, target = false) => {
      const { headers, endpoint } = getEndpointInfo(
        target ? target : environment,
      );
      headers.tenantId = tenant ? tenant : tenantId;
      return axios
          .post(`${endpoint}${uri}`, data, { headers: headers })
          .then((response) => response)
          .catch((error) => console.log(error));
    },
    [environment, tenantId],
  );
  const patchApi = useCallback(
    async (uri, data, target = false) => {
      const { headers, endpoint } = getEndpointInfo(
        target ? target : environment,
      );
      return axios
        .patch(`${endpoint}${uri}`, data, { headers: headers })
        .then((response) => response)
        .catch((error) => console.log(error));
    },
    [environment],
  );

  const patchApiAsTenant = useCallback(
    async (uri, data, tenant = false, target = false) => {
      const { headers, endpoint } = getEndpointInfo(
        target ? target : environment,
      );
      headers.tenantId = tenant ? tenant : tenantId;
      return async (uri, data) =>
        axios
          .patch(`${endpoint}${uri}`, data, { headers: headers })
          .then((response) => response)
          .catch((error) => console.log(error));
    },
    [environment, tenantId],
  );

  return {
    getApi,
    getApiAsTenant,
    postApi,
    postApiAsTenant,
    patchApi,
    patchApiAsTenant,
  };
};

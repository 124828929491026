import React, { useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorageIcon from "@mui/icons-material/Storage";
import {
  DataObject,
  Checklist,
  ToggleOn,
  AccountTree,
  FilterAlt,
  SystemSecurityUpdateGood,
  LibraryBooks,
  Microsoft,
  Album,
  Settings,
  Widgets,
  TrendingUp,
  AssessmentOutlined,
  SupervisedUserCircle,
  Apple,
  Try,
  DynamicFeedRounded,
  Terminal,
  BallotOutlined,
  MonitorHeart,
} from "@mui/icons-material";
import { Drawer, Toolbar } from "@mui/material";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ReactComponent as NumiatoLogo } from "../../images/numiato-mini.svg";
function Sidebar() {
  const drawerWidth = 250;
  const [openDataMigration, setOpenDataMigration] = useState(false);
  const [openDataManagement, setOpenDataManagement] = useState(false);
  const [openCustomers, setOpenCustomers] = useState(false);
  const [openTasks, setOpenTasks] = useState(false);
  const toggleDataMigration = () => {
    setOpenDataMigration(!openDataMigration);
  };
  const toggleDataManagement = () => {
    setOpenDataManagement(!openDataManagement);
  };
  const toggleCustomers = () => setOpenCustomers(!openCustomers);
  const toggleTasks = () => setOpenTasks(!openTasks);
  const menuOptions = [
    { name: "Dashboard", link: "/", Logo: DashboardIcon },
    {
      name: "Customers",
      link: "/customers",
      Logo: SupervisedUserCircle,
      collapsable: true,
      items: [
        {
          name: "Customer List",
          link: "/customers/",
          Logo: SupervisedUserCircle,
        },
        { name: "Monitoring", link: "/customers/monitor", Logo: MonitorHeart },
        { name: "Apple Tokens", link: "/customers/appletokens", Logo: Apple },
        { name: "Numiato View", link: "/numiato", Logo: NumiatoLogo },
        { name: "Bulk tasks", link: "/bulk", Logo: DynamicFeedRounded },
      ],
      open: openCustomers,
      setOpen: toggleCustomers,
    },
    // {
    //   name: "Tasks",
    //   Logo: AssignmentTurnedIn,
    //   collapsable: true,
    //   items: [
    //     {
    //       name: "Approved drivers",
    //       link: "/tasks/results/backoffice",
    //       Logo: Handyman,
    //     },
    //   ],
    //   open: openTasks,
    //   setOpen: toggleTasks,
    // },
    {
      name: "Data Migration",
      Logo: StorageIcon,
      collapsable: true,
      open: openDataMigration,
      setOpen: toggleDataMigration,
      items: [
        { name: "AppStore" },
        { name: "Applications", link: "/datamigration/appstore", Logo: Widgets },
        // { name: "Win32Apps", link: "/datamigration/w32", Logo: Widgets },
        // { name: "MSI Apps", link: "/datamigration/msi", Logo: Album },
        // { name: "Windows Store", link: "/datamigration/was", Logo: Microsoft },
        { name: "Policy Management" },
        { name: "Objects", link: "/datamigration/objects", Logo: DataObject },
        { name: "Profiles", link: "/datamigration/profiles", Logo: ToggleOn },
        { name: "Settings", link: "/datamigration/settings", Logo: Checklist },
        { name: "Policies", link: "/datamigration/policy", Logo: AccountTree },
        { name: "Filters", link: "/datamigration/filter", Logo: FilterAlt },
        { name: "Remediations", link: "/datamigration/remediations", Logo: Terminal },
        { name: "Shell Scripts", link: "/datamigration/shellscripts", Logo: BallotOutlined },
        { name: "Device Management" },
        {
          name: "OS Versions",
          link: "/datamigration/osversion",
          Logo: SystemSecurityUpdateGood,
        },
        {
          name: "Metrics",
          link: "/datamigration/metric",
          Logo: TrendingUp,
        },
        {
          name: "Checks",
          link: "/datamigration/check",
          Logo: AssessmentOutlined,
        },
        {
          name: "Discovered Apps",
          link: "/datamigration/discoveredApps",
          Logo: Try,
        },
      ],
    },
    {
      name: "Data Management",
      Logo: LibraryBooks,
      collapsable: true,
      open: openDataManagement,
      setOpen: toggleDataManagement,
      items: [
        { name: "AppStore" },
        { name: "Applications", link: "/datamanagement/appstore", Logo: Widgets },
        // { name: "Win32Apps", link: "/datamanagement/w32", Logo: Widgets },
        // { name: "MSI Apps", link: "/datamanagement/msi", Logo: Album },
        // { name: "Windows Store", link: "/datamanagement/was", Logo: Microsoft },
        { name: "Policy Management" },
        { name: "Objects", link: "/datamanagement/objects", Logo: DataObject },
        { name: "Profiles", link: "/datamanagement/profiles", Logo: ToggleOn },
        { name: "Settings", link: "/datamanagement/settings", Logo: Checklist },
        { name: "Policies", link: "/datamanagement/policy", Logo: AccountTree },
        { name: "Filters", link: "/datamanagement/filter", Logo: FilterAlt },
      ],
    },
    { name: "Settings", link: "/settings", Logo: Settings },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <List>
        {menuOptions.map((info) => {
          return info.collapsable ? (
            <>
              <ListItemButton onClick={info.setOpen} key={info.name}>
                <ListItemIcon>
                  <info.Logo />
                </ListItemIcon>
                <ListItemText primary={info.name} />
                {info.open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={info.open} unmountOnExit>
                <List>
                  {info.items.map((sub) =>
                    sub.link ? (
                      <ListItemButton
                        component={Link}
                        to={sub.link}
                        sx={{ pl: 4 }}
                        key={sub.index}
                      >
                        <ListItemIcon>
                          <sub.Logo />
                        </ListItemIcon>
                        <ListItemText primary={sub.name} />
                      </ListItemButton>
                    ) : (
                      <ListSubheader sx={{ pl: 4 }} key={sub.index}>
                        {sub.name}
                      </ListSubheader>
                    )
                  )}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItemButton component={Link} to={info.link} key={info.name}>
              <ListItemIcon>
                <info.Logo />
              </ListItemIcon>
              <ListItemText primary={info.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Drawer>
  );
}

export default Sidebar;

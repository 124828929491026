import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Navbar from "./components/Navbar/Navbar";
import "@fontsource/poppins";
import { BackofficeContext } from "./tools/BackofficeContext";
import Home from "./pages/home";
import UC from "./pages/underconstruction";
import Login from "./pages/login";
import PMObject from "./pages/datamigration/object";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import Sidebar from "./components/Navbar/Sidebar";
import { Routes, Route } from "react-router-dom";
import { Toolbar, Box, Container } from "@mui/material";
import { backofficeTheme } from "./theme";
import PMProfiles from "./pages/datamigration/profiles";
import OsVersions from "./pages/datamigration/osVersions";
import Filter from "./pages/datamigration/Filter";
import Metric from "./pages/datamigration/Metric";
import PMSettings from "./pages/datamigration/Settings";
import Policies from "./pages/datamigration/Policies";
import DMCheck from "./pages/datamigration/Check";
import DiscoveredApp from "./pages/datamigration/DiscoveredApp";
import CustomerList from "./pages/customers/list";
import AppleTokens from "./pages/customers/appleTokens";
import Authorizing from "./components/auth/Authorizing";
import TenantProfilePage from "./pages/customers/TenantProfile";
import Settings from "./pages/settings";
import NumiatoRealm from "./pages/numiato/NumiatoRealm";
import BulkTasks from "./pages/bulkTasks";
import MonitorMetrics from "./pages/customers/monitorMetrics";
import { getTranslations } from "./tools/i18n";
import {
  DataMigrationContext,
  useInitializeDataMigrationContext,
} from "./contexts/DataMigrationContext";
import Remediation from "./pages/datamigration/Remediation";
import ShellScript from "./pages/datamigration/ShellScript";
function App() {
  const [authenticated, setAuthenticated] = useState(
    Providers.globalProvider.state === ProviderState.SignedIn
  );
  const [prdToken, setPrdToken] = useState(
    sessionStorage.getItem("prdToken") || null
  );
  const [uatToken, setUatToken] = useState(
    sessionStorage.getItem("prdToken") || null
  );
  const [locToken, setLocToken] = useState(
    sessionStorage.getItem("prdToken") || null
  );
  const [authorized, setAuthorized] = useState(false);
  const [environment, setEnvironment] = useState("PRD");
  const [tenantId, setTenantId] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const makeRefresh = () => setRefresh((p) => p + 1);
  const dataMigrationContext = useInitializeDataMigrationContext();
  Providers.globalProvider.onStateChanged(() => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      let authInfo = {};
      Providers.me()
        .then(
          (info) =>
            (authInfo = {
              username: info.userPrincipalName,
              password: info.id,
            })
        )
        .then(() => {
          setAuthenticated(true);
          // Authenticate PRD
          axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT}/authenticate/`,
              authInfo
            )
            .then((response) => {
              sessionStorage.setItem("prdToken", response.data.token);
              setPrdToken(response.data.token);
            })
            .catch(() => {
              console.log("No production API responded");
              setPrdToken(false);
            });
          // Authenticate UAT
          axios
            .post(
              `${process.env.REACT_APP_UATAPI_ENDPOINT}/authenticate/`,
              authInfo
            )
            .then((response) => {
              sessionStorage.setItem("uatToken", response.data.token);
              setUatToken(response.data.token);
            })
            .catch(() => {
              console.log("No development API responded");
              setUatToken(false);
            });
          // Authenticate LOC
          axios
            .post(
              `${process.env.REACT_APP_LOCAPI_ENDPOINT}/authenticate/`,
              authInfo
            )
            .then((response) => {
              sessionStorage.setItem("locToken", response.data.token);
              setLocToken(response.data.token);
            })
            .catch(() => {
              console.log("No local API responded");
              setLocToken(false);
            });
        });
    }
  });

  useEffect(() => {
    getTranslations();
  }, []);

  return (
    <ThemeProvider theme={backofficeTheme}>
      {!authenticated ? (
        <Login />
      ) : (
        <BackofficeContext.Provider
          value={{
            authenticated,
            setAuthenticated,
            prdToken,
            setPrdToken,
            uatToken,
            setUatToken,
            locToken,
            setLocToken,
            authorized,
            setAuthorized,
            environment,
            setEnvironment,
            tenantId,
            setTenantId,
            makeRefresh,
            refresh,
          }}
        >
          {/* Authenticated */}
          <Navbar />
          <div style={{ display: "flex", height: "100vh", width: "100%" }}>
            {authorized && <Sidebar />}
            <Box
              sx={{ py: 5 }}
              style={{
                display: "flex",
                flexGrow: 1,
              }}
            >
              {authorized ? (
                <Container maxWidth="xl">
                  <DataMigrationContext.Provider value={dataMigrationContext}>
                    <Toolbar /> {/*Just for spacing */}
                    <Routes>
                      <Route exact path="/" element={<Home />} />
                      <Route
                        path="/datamigration/objects"
                        element={<PMObject />}
                      />
                      <Route
                        path="/datamigration/settings"
                        element={<PMSettings />}
                      />
                      <Route
                        path="/datamigration/profiles"
                        element={<PMProfiles />}
                      />
                      <Route
                        path="/datamigration/policy"
                        element={<Policies />}
                      />

                      <Route
                        path="/datamigration/filter"
                        element={<Filter />}
                      />
                      <Route
                        path="/datamigration/remediations"
                        element={<Remediation />}
                      />
                      <Route
                        path="/datamigration/shellscripts"
                        element={<ShellScript />}
                      />
                      <Route
                        path="/datamigration/osversion"
                        element={<OsVersions />}
                      />
                      <Route
                        path="/datamigration/discoveredapps"
                        element={<DiscoveredApp />}
                      />
                      <Route
                        path="/datamigration/metric"
                        element={<Metric />}
                      />
                      <Route
                        path="/datamigration/check"
                        element={<DMCheck />}
                      />
                      <Route path="/customers" element={<CustomerList />} />
                      <Route
                        path="/customers/:tenantId"
                        element={<TenantProfilePage />}
                      />
                      <Route
                        path="/customers/appletokens"
                        element={<AppleTokens />}
                      />
                      <Route
                        path="/customers/monitor"
                        element={<MonitorMetrics />}
                      />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/numiato" element={<NumiatoRealm />} />
                      <Route path="/bulk" element={<BulkTasks />} />

                      {/* <Route path="/tasks/results/backoffice" element={<ResultsBackoffice/>} /> */}

                      <Route path="/data/adf" element={<div>adf</div>} />
                      <Route path="*" element={<UC />} />
                    </Routes>
                  </DataMigrationContext.Provider>
                </Container>
              ) : (
                <Authorizing />
              )}
            </Box>
          </div>
        </BackofficeContext.Provider>
      )}
    </ThemeProvider>
  );
}

export default App;

import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMonitorResults } from "../../hooks/customers/useMonitorResults";
function CustomerTable({ data, openTenant }) {
  const navigate = useNavigate();

  const [flatData, setFlatData] = useState([]);
  const getNumiatoState = (status) => {
    switch (status) {
      case "1000":
      case "1001":
        return <Tooltip title={`Trial ${status}`}>🟠</Tooltip>;
      case "1010":
        return <Tooltip title={`OK ${status}`}>🟢</Tooltip>;
      case "1003":
        return <Tooltip title={`Limited ${status}`}>🔸</Tooltip>;
      case "1101":
      case "1110":
      case "1201":
        return <Tooltip title={`Expired ${status}`}>🔴</Tooltip>;
      case "1002":
      case "1012":
        return <Tooltip title={`Expiring ${status}`}>🟡</Tooltip>;
      default:
        return <Tooltip title={`Unknown ${status}`}>⚪️</Tooltip>;
    }
  };
  const getStatusIcon = (status) => {
    return status ? <span>🟢</span> : <span>🔴</span>;
  };
  const getMonitorState = (monitor) => {
    const warning = monitor?.warning || 0;
    return (
      <Tooltip
        title={`Success ${monitor?.success || 0}\n
            Failed ${monitor?.error || 0} \n
            Warning ${monitor?.warning || 0}\n
            Snoozed ${monitor?.snoozed || 0}`}
        sx={{ whiteSpace: "pre-line" }}
      >
        {warning ? <span>🔴</span> : <span>🟢</span>}
      </Tooltip>
    );
  };
  const getVis365Status = (status) => {
    switch (status) {
      case "510":
      case "501":
        return <Tooltip title={`Trial ${status}`}>🟠</Tooltip>;
      case "521":
      case "522":
        return <Tooltip title={`OK ${status}`}>🟢</Tooltip>;
      case "502":
      case "503":
        return <Tooltip title={`Expired ${status}`}>🔴</Tooltip>;
      case "600":
        return <Tooltip title={`Terminated ${status}`}>⛔️</Tooltip>;
      case "523":
        return <Tooltip title={`Expiring ${status}`}>🟡</Tooltip>;
      default:
        return <Tooltip title={`Unknown ${status}`}>⚪️</Tooltip>;
    }
  };
  useEffect(() => {
    let info = data;
    info.map((line) => {
      line.windowsPolicyManagement =
        line.management_info?.windowsPolicyManagement === true || false;
      line.ipadPolicyManagement =
        line.management_info?.ipadPolicyManagement === true || false;
      line.macosPolicyManagement =
        line.management_info?.macosPolicyManagement === true || false;
      line.macosManagement =
        line.management_info?.macosOnboarded === true || false;
      line.ipadManagement =
        line.management_info?.ipadOnboarded === true || false;
      line.enrollmentAndroid =
        line.management_info?.enrollmentRestrictions?.android
          ?.platformBlocked === false &&
        line.management_info?.enrollmentRestrictions?.android
          ?.personalDeviceEnrollmentBlocked === true;
      line.enrollmentIpad =
        line.management_info?.enrollmentRestrictions?.ipad?.platformBlocked ===
          false &&
        line.management_info?.enrollmentRestrictions?.ipad
          ?.personalDeviceEnrollmentBlocked === true;
      line.enrollmentMacos =
        line.management_info?.enrollmentRestrictions?.macos?.platformBlocked ===
          false &&
        line.management_info?.enrollmentRestrictions?.macos
          ?.personalDeviceEnrollmentBlocked === true;
      line.enrollmentWindows =
        line.management_info?.enrollmentRestrictions?.windows
          ?.platformBlocked === false &&
        line.management_info?.enrollmentRestrictions?.windows
          ?.personalDeviceEnrollmentBlocked === true;
      line.monitor = line.management_info?.monitorSummary;
    });
    setFlatData(info);
  }, [data]);

  const columns = [
    {
      field: "tenant_id",
      headerName: "Tenant Id",
      headerAlign: "center",
      width: 350,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 350,
    },
    {
      field: "numiato_status",
      headerName: "Numiato Status",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => getNumiatoState(params.row.numiato_status),
      align: "center",
    },
    {
      field: "monitor",
      headerName: "Monitor Status",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => getMonitorState(params?.value),
      align: "center",
      sortable: true,
      sortComparator: (a, b) => {
        const wA = a?.warning || 0;
        const wB = b?.warning || 0;
        return wB - wA;
      },
    },
    {
      field: "vis365_status",
      headerName: "Vis365 Status",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => getVis365Status(params.row.vis365_status),
      align: "center",
    },
    {
      field: "association",
      headerName: "Association",
      headerAlign: "center",
      width: 350,
      align: "center",
    },
    {
      field: "ipadManagement",
      headerName: "iPad",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "macosManagement",
      headerName: "macOS",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "windowsPolicyManagement",
      headerName: "Windows",
      headerAlign: "center",
      width: 105,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "ipadPolicyManagement",
      headerName: "iPad",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "macosPolicyManagement",
      headerName: "macOS",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "enrollmentWindows",
      headerName: "Windows",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "enrollmentMacos",
      headerName: "macOS",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "enrollmentAndroid",
      headerName: "Android",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "enrollmentIpad",
      headerName: "iPad",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => getStatusIcon(params?.value),
      align: "center",
    },
    {
      field: "domains",
      valueGetter: (value) => {
        try {
          return value.join();
        } catch {
          return "";
        }
      },
    },
    {
      field: "users",
      valueGetter: (value) => {
        try {
          return value.join();
        } catch {
          return "";
        }
      },
    },
  ];
  const columnGroupModel = [
    {
      groupId: "Enrollment Restrictions",
      children: [
        { field: "enrollmentMacos" },
        { field: "enrollmentAndroid" },
        { field: "enrollmentWindows" },
        { field: "enrollmentIpad" },
      ],
    },
    {
      groupId: "Policy Management",
      children: [
        { field: "macosPolicyManagement" },
        { field: "windowsPolicyManagement" },
        { field: "ipadPolicyManagement" },
      ],
    },
    {
      groupId: "Device Management",
      children: [{ field: "macosManagement" }, { field: "ipadManagement" }],
    },
  ];
  return (
    <>
      {data && (
        <Box
          sx={{
            py: 3,
            height: "88%",
            "& .table-head": {
              backgroundColor: "#474443",
              fontWeight: 700,
            },
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            columnGroupingModel={columnGroupModel}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 20 } },
              columns: {
                columnVisibilityModel: {
                  domains: false,
                  users: false,
                  tenant_id: false,
                  vis365_status: false,
                  enrollmentAndroid: false,
                  association: false,
                },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: false,
                  // quickFilterValues:['vogl']
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
            // disableColumnFilter
            // disableColumnSelector
            disableDensitySelector
            onRowClick={(params) =>
              navigate(`/customers/${params.row.tenant_id}`)
            }
          />
        </Box>
      )}
    </>
  );
}

export default CustomerTable;

import { useState, useCallback, useEffect } from "react";
import { useApi } from "../useApi";
import { Button } from "@mui/material";
import { keyframes } from "@emotion/react";
const pulse = keyframes`
0% { opacity: 1; }
50% { opacity: 0.6; }
100% { opacity: 1; }
`;
export const useMonitorResults = (tenantId) => {
  const [results, setResults] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [tenantStatus, setTenantStatus] = useState("unknown");
  const [loading, setLoading] = useState(false);
  const [updateNeeded, setUpdateNeeded] = useState(false);
  const { getApiAsTenant, postApiAsTenant } = useApi();

  const fetchResults = useCallback(() => {
    setLoading(true);
    getApiAsTenant(`/monitor/`, tenantId)
      .then((response) => {
        const sortedMetrics = response.data.map((metric) => {
          if (!metric.results || metric.results.length === 0) {
            return { ...metric, state: "unknown", latest: null, results: [] };
          }

          // Sort results by timestamp (newest first)
          const sortedResults = [...metric.results].sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );

          // Get the latest result
          const latestResult = sortedResults[0];

          return {
            ...metric,
            state: metric.enabled ? latestResult.state : "snoozed", // Assign latest state
            latest: latestResult.timestamp, // Assign latest timestamp
            results: sortedResults, // Assign sorted results
          };
        });

        // Step 1: Sort first by category, then by name
        sortedMetrics.sort((a, b) => {
          const categoryA = a.category?.toLowerCase() || "uncategorized";
          const categoryB = b.category?.toLowerCase() || "uncategorized";
          if (categoryA !== categoryB) {
            return categoryA.localeCompare(categoryB); // Sort by category first
          }
          return a.name.localeCompare(b.name); // Sort by name within the same category
        });

        // Step 2: Group metrics by category
        const groupedMetrics = sortedMetrics.reduce((acc, metric) => {
          const category = metric.category || "Uncategorized";
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(metric);
          return acc;
        }, {});

        setResults(groupedMetrics);
      })
      .finally(() => {
        setLoading(false);
        setUpdateNeeded(false);
      });
  }, [getApiAsTenant, tenantId]);

  useEffect(() => {
    if (!results) return;
    // Filter metrics whose state is NOT "success"
    const nonSuccessMetrics = Object.values(results)
      .flat() // Flatten the array of arrays
      .filter((metric) => (metric.state !== "success" && metric.state !== "snoozed"))
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name

    // Update alerts state
    setAlerts(nonSuccessMetrics);
  }, [results]);

  useEffect(() => {
    fetchResults();
  }, [fetchResults, tenantId]);

  const submitCheck = useCallback(
    (metricId) => {
      postApiAsTenant(
        `/monitor/check/${tenantId}/${metricId}/`,
        {},
        tenantId
      ).then(() => {
        setUpdateNeeded(true);
      });
    },
    [postApiAsTenant, tenantId]
  );

  const loadMore = useCallback(
    ({ metricId }) => {
      const currentMetric = results.find((r) => r.id === metricId);
      if (currentMetric?.loadMore) {
        const days = 5 * currentMetric.loadMore;
      }
    },
    [results]
  );

  const RefreshButton = useCallback(() => {
    return (
      <Button
        variant="contained"
        onClick={fetchResults}
        sx={{
          animation: updateNeeded ? `${pulse} 1.5s infinite` : "none",
        }}
      >
        Refresh
      </Button>
    );
  }, [fetchResults, updateNeeded]);
  const submitAction = useCallback(
    (action, selected) => {
      postApiAsTenant(
        `/monitor/job/`,
        { action: action, metrics: selected, tenantId:tenantId },
        tenantId
      ).then(() => {
        setUpdateNeeded(true);
      });
    },
    [postApiAsTenant, tenantId]
  );

  useEffect(() => {
    let warning = 0;
    for (const r of alerts) {
      if (r.warning || r.error) {
        warning += 1;
      }
    }
    setTenantStatus(warning);
  }, [alerts, setTenantStatus]);

  return {
    monitorMetrics: results,
    alertMetrics: alerts,
    tenantStatus,
    loading,
    submitCheck,
    RefreshButton,
    submitAction,
  };
};

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  Bolt,
  Cancel,
  CheckCircle,
  DesktopAccessDisabled,
  DesktopMacOutlined,
  DoNotDisturb,
  KeyboardArrowLeft,
  MobileOffRounded,
  Tablet,
  Warning,
} from "@mui/icons-material";
import { NumiatoStatus, Vis365Status } from "../system/LicensingItems";
import moment from "moment";
import { GetApi, usePostApiAsTenant } from "../../tools/axiosFunctions";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { LoadingTable } from "../system/layout/LoadingElements";
import AppleClassroomTab from "./tenantProfile/AppleClassroomTab";
import { getBackofficeTaskBasePayload } from "../numiato/backoffice/TaskComponents";
import { useNotification } from "../system/NotificationCenter";
import MonitoringResultsPage from "./tenantProfile/MonitoringResultsPage";
function TenantProfile({ info, back }) {
  const [tab, setTab] = useState(0);
  const handleChange = (e, n) => setTab(n);
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ paddingTop: 2 }}>
        <Typography variant="h5">
          <>
            <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
            </IconButton>
            {info.name}
          </>
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Basic info" />
            <Tab label="Management Tasks" />
            <Tab label="Approved drivers" />
            <Tab label="Apple Classroom" />
            <Tab label="Monitoring" />
          </Tabs>
        </Box>
        <CustomTabPanel tab={tab} index={0}>
          <TenantBasicInfo tenant={info} />
        </CustomTabPanel>
        <CustomTabPanel tab={tab} index={1}>
          <TenantManagementTasks tenant={info} />
        </CustomTabPanel>
        <CustomTabPanel tab={tab} index={2}>
          <ApprovedDriversTable info={info} />
        </CustomTabPanel>
        <CustomTabPanel tab={tab} index={3}>
          <AppleClassroomTab tenant={info} />
        </CustomTabPanel>
        <CustomTabPanel tab={tab} index={4}>
          <MonitoringResultsPage tenantId={info.tenant_id} />
        </CustomTabPanel>
      </Box>
    </>
  );
}
export default TenantProfile;

function CustomTabPanel(props) {
  const { children, tab, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tab === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function TenantBasicInfo({ tenant }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4}>
          <Typography variant="h6">Details</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem>
              <ListItemText primary={tenant.tenant_id} secondary="Tenant Id" />
            </ListItem>
            <ListItem>
              <ListItemText primary={tenant.name} secondary="Tenant Name" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={NumiatoStatus(tenant.numiato_status)}
                secondary="Numiato License"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${moment(tenant.license_expiration).format(
                  "DD/MM/YYYY"
                )} (${moment(tenant.license_expiration).fromNow()})`}
                secondary="License expiration"
              />
            </ListItem>
            {!tenant.direct_customer && <ListItem>
              <ListItemText
                primary={Vis365Status(tenant.vis365_status)}
                secondary="Vis365 Status"
              />
            </ListItem>}
            {tenant.association && (
              <ListItem>
                <ListItemText
                  primary={tenant.association}
                  secondary="Association"
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={
                  tenant.direct_customer ? "Direct customer" : "Vis365 customer"
                }
                secondary="Customer Type"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${moment(tenant.last_updated).format(
                  "DD/MM/YYYY"
                )} (${moment(tenant.last_updated).fromNow()})`}
                secondary="Last updated"
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Typography variant="h6">Users</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {tenant.users.map((user) => (
              <ListItem>{user}</ListItem>
            ))}
          </List>
          {tenant.verified_domains && (
            <>
              <Typography variant="h6">Domains</Typography>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {tenant.verified_domains.map((domain) => (
                  <ListItem>{domain}</ListItem>
                ))}
              </List>
            </>
          )}
          <Typography variant="h6">Registered Devices</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem>
              <ListItemText
                primary={tenant.management_info.enrolled_windows || "-"}
                secondary="Enrolled Windows"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={tenant.management_info.unenrolled_windows || "-"}
                secondary="Unenrolled Windows"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={tenant.management_info.enrolled_ipads || "-"}
                secondary="Enrolled iPads"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={tenant.management_info.unenrolled_ipads || "-"}
                secondary="Unenrolled iPads"
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Typography variant="h6">Windows Policy Management</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <>
              <ListItem>
                <BoolListAvatar
                  status={tenant.management_info.windowsPolicyManagement}
                />
                <ListItemText
                  primary={
                    tenant.management_info.windowsPolicyManagement
                      ? "Onboarded"
                      : "Not onboarded"
                  }
                />
              </ListItem>
            </>
          </List>
          <Typography variant="h6">iPad Management</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {tenant.management_info?.ipadPolicyManagement === true ? (
              <>
                <ListItem>
                  <BoolListAvatar status={tenant.management_info.asm} />
                  <ListItemText primary={"Apple School Manager Token"} />
                </ListItem>
                <ListItem>
                  <BoolListAvatar status={tenant.management_info.mdm} />
                  <ListItemText primary={"MDM-Push cerrtificate"} />
                </ListItem>
                <ListItem>
                  <BoolListAvatar status={tenant.management_info.scim} />
                  <ListItemText primary={"SCIM Device Provisioning"} />
                </ListItem>
                <ListItem>
                  <BoolListAvatar status={tenant.management_info.vpp} />
                  <ListItemText primary={"Apple VPP Token"} />
                </ListItem>
                <ListItem>
                  <BoolListAvatar
                    status={tenant.management_info.unternehmensportal}
                  />
                  <ListItemText primary={"Unternehmensportal App"} />
                </ListItem>
                <ListItem>
                  <BoolListAvatar
                    status={
                      Date.parse(tenant.management_info.expiration) >=
                      new Date()
                    }
                  />
                  <ListItemText
                    primary={`${moment(
                      tenant.management_info.expiration
                    ).format("DD/MM/YYYY")} (${moment(
                      tenant.management_info.expiration
                    ).fromNow()})`}
                    secondary={"Expiration"}
                  />
                </ListItem>
              </>
            ) : (
              <>
                <ListItem>
                  <ListItemAvatar>
                    {tenant.management_info?.ipadOnboarded === "requested" ? (
                      <Bolt />
                    ) : (
                      <DoNotDisturb />
                    )}
                  </ListItemAvatar>
                  {tenant.management_info?.ipadOnboarded === "requested" ? (
                    <ListItemText primary={"Requested"} />
                  ) : (
                    <ListItemText primary={"Not onboarded"} />
                  )}
                </ListItem>
              </>
            )}
          </List>
          <Typography variant="h6">macOS Management</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem>
              <BoolListAvatar status={tenant.management_info.macosOnboarded} />
              <ListItemText
                primary={
                  tenant.management_info?.macosOnboarded === "requested"
                    ? "Requested"
                    : tenant.management_info?.macosOnboarded
                    ? "Onboarded"
                    : "Not onboarded"
                }
                secondary={"Device/Application Management"}
              />
            </ListItem>
            <ListItem>
              <BoolListAvatar
                status={tenant.management_info.macosPolicyManagement}
              />
              <ListItemText
                primary={
                  tenant.management_info?.macosPolicyManagement === "requested"
                    ? "Requested"
                    : tenant.management_info?.macosPolicyManagement
                    ? "Onboarded"
                    : "Not onboarded"
                }
                secondary={"Policy Management"}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
}

function BoolListAvatar({ status }) {
  if (status === "warning" || status === "requested") {
    return (
      <ListItemAvatar>
        <Warning sx={{ color: "orange" }} />
      </ListItemAvatar>
    );
  }
  if (status === true) {
    return (
      <ListItemAvatar>
        <CheckCircle sx={{ color: "green" }} />
      </ListItemAvatar>
    );
  } else if (status === false) {
    return (
      <ListItemAvatar>
        <Cancel sx={{ color: "red" }} />
      </ListItemAvatar>
    );
  }
}

function ApprovedDriversTable({ info }) {
  const [drivers, setDrivers] = useState(false);
  useEffect(() => {
    GetApi("PRD", `/tasks/backoffice/approveddrivers/${info.tenant_id}`).then(
      (response) => {
        let driversInfo = [];
        response.data.map((task) => {
          task.drivers.map((dri) => {
            driversInfo.push({
              approvedOn: new Date(task.approvedOn),
              releasedDate: new Date(dri.releaseDateTime),
              ...dri,
            });
          });
        });
        setDrivers(driversInfo);
      }
    );
  }, [info]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 350,
    },
    {
      field: "version",
      headerName: "Version",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "applicableDeviceCount",
      headerName: "Devices",
      align: "center",
      headerAlign: "center",
      type: "number",
    },
    {
      field: "releasedDate",
      headerName: "Released",
      align: "center",
      headerAlign: "center",
      // valueGetter: (params) => new Date(params.value),
      width: 200,
      type: "date",
    },

    {
      field: "approvedOn",
      headerName: "Approved On",
      align: "center",
      headerAlign: "center",
      width: 200,
      type: "date",
    },
  ];
  return (
    <>
      <Box
        sx={{
          py: 3,
          height: "75%",
          "& .table-head": {
            backgroundColor: "#474443",
            fontWeight: 700,
          },
        }}
      >
        {drivers ? (
          drivers.length > 0 ? (
            <DataGrid
              rows={drivers}
              columns={columns}
              initialState={{
                ...drivers.initialState,
                pagination: { paginationModel: { pageSize: 20 } },
                columns: {
                  columnVisibilityModel: {},
                },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                },
              }}
              // disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <Typography variant="h6" align="center">
              No Drivers found
            </Typography>
          )
        ) : (
          <LoadingTable />
        )}
      </Box>
    </>
  );
}

function TenantManagementTasks({ tenant }) {
  const postApiAsTenant = usePostApiAsTenant(tenant.tenant_id);
  const { sendNotification } = useNotification();
  const handleCreateEnrollment = (target) => {
    const payload = getBackofficeTaskBasePayload();
    payload.detail = {
      kwargs: {
        tenantId: tenant.tenant_id,
      },
    };

    switch (target) {
      case "macos":
        payload.detail.task = "device.tasks.task_onboard_macos";
        break;
      case "ipad":
        payload.detail.task = "device.tasks.task_onboard_ipad";
        break;
      default:
        return;
    }
    postApiAsTenant(`/tasks/backoffice/submit/`, payload).then((response) => {
      switch (target) {
        case "macos":
          sendNotification(
            "Task submitted for macOS enrollment profiles",
            "success"
          );
          break;
        case "ipad":
          sendNotification(
            "Task submitted for iPad enrollment profiles",
            "success"
          );
          break;
      }
    });
  };
  const handleCancelOnboarding = (target) => {
    postApiAsTenant(`/settings/${target}/cancelonboard/`, {}).then(() =>
      sendNotification(`${target} onboarding request cancelled.`, "success")
    );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4}>
          <Typography variant="h6">Device Management</Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem>
              <ListItemButton onClick={() => handleCreateEnrollment("ipad")}>
                <ListItemAvatar>
                  <Avatar>
                    <Tablet sx={{ transform: "rotate(90deg)" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"Create iPad Enrollment Profiles"} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => handleCreateEnrollment("macos")}>
                <ListItemAvatar>
                  <Avatar>
                    <DesktopMacOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"Create macOS Enrollment Profiles"} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => handleCancelOnboarding("ipad")}>
                <ListItemAvatar>
                  <Avatar>
                    <MobileOffRounded />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"Cancel iPad Onboarding Request"} />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => handleCancelOnboarding("macos")}>
                <ListItemAvatar>
                  <Avatar>
                    <DesktopAccessDisabled />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"Cancel macOS Onboarding Request"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MonitorMetricResults from "./MonitorMetricResults"; // Ensure this component exists
import MonitorMetric, { MetricIcon } from "./MonitorMetric";

function CategorizedMonitorMetrics({ metricsData }) {
  return (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        All metrics
      </Typography>
      {Object.entries(metricsData).map(([category, metrics]) => {
        // Count the number of each state
        const stateCounts = metrics.reduce(
          (acc, metric) => {
            acc[metric.state] = (acc[metric.state] || 0) + 1;
            return acc;
          },
          { warning: 0, success: 0, snoozed: 0 }
        );

        return (
          <Accordion key={category}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {category}
              </Typography>

              {/* Box with Metric Icons on the right */}
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <>
                  <MetricIcon state="success" />
                  <Typography variant="h6">{stateCounts.success}</Typography>
                  <MetricIcon state="warning" />
                  <Typography variant="h6">{stateCounts.warning}</Typography>
                  <MetricIcon state="snoozed" />
                  <Typography variant="h6">{stateCounts.snoozed}</Typography>
                </>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {metrics.map((metric) => (
                <MonitorMetric metric={metric} key={metric.metricId} />
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Paper>
  );
}

export default CategorizedMonitorMetrics;

import { Button, Grid,Paper, Typography } from "@mui/material";
import { useMonitorResults } from "../../../hooks/customers/useMonitorResults";
import MonitorMetric from "./MonitorMetric";
import MonitorMetricActionsDropdown from "./MonitorMetricActionsDropdown";
import { useState } from "react";
import { LoadingSkeleton } from "../../system/layout/LoadingElements";
import { useNotification } from "../../system/NotificationCenter";
import CategorizedMonitorMetrics from "./CategorizedMonitorMetrics";
function MonitoringResultsPage({ tenantId }) {
  const { sendNotification } = useNotification();
  const { monitorMetrics,alertMetrics, submitCheck, RefreshButton, submitAction } =
    useMonitorResults(tenantId);
  const [selected, setSelected] = useState([]);
  const handleSubmitAction = (action) => {
    submitAction(action, selected);
    sendNotification(
      "Request submitted, refresh within the next minute to see fresh results.",
      "success"
    );
    deselectAll();
  };
  const toggleSelect = (id) => {
    setSelected(
      selected.includes(id)
        ? selected.filter((i) => i !== id)
        : [...selected, id]
    );
  };
  const selectAll = () => {
    setSelected(alertMetrics.map((m) => m.metricId));
  };
  const deselectAll = () => {
    setSelected([]);
  };
  return (
    <>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <MonitorMetricActionsDropdown
            submitAction={handleSubmitAction}
            enabled={selected.length > 0}
          />
        </Grid>

        <Grid item sx={{ display: "flex", gap: 2, paddingY: 2 }}>
          {selected.length > 0 && (
            <Button variant="contained" onClick={deselectAll}>
              Deselect All
            </Button>
          )}
          <Button variant="contained" onClick={selectAll}>
            Select All
          </Button>

          <RefreshButton />
        </Grid>
      </Grid>
      
      {alertMetrics.length > 0 && <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Warnings
      </Typography>
        {alertMetrics.map((metric, index) => (
          <MonitorMetric
            key={index}
            metric={metric}
            submitCheck={submitCheck}
            selected={selected.includes(metric.metricId)}
            toggleSelect={toggleSelect}
          />
        ))
      }
      </Paper>}
      {monitorMetrics ? <><CategorizedMonitorMetrics metricsData={monitorMetrics} /> </> : (
        <Grid item>
          <LoadingSkeleton />
        </Grid>
      )}
    </>
  );
}
export default MonitoringResultsPage;

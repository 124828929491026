import { useCallback, useEffect, useState } from "react";
import { useApi } from "../useApi";
import { Button } from "@mui/material";
import { keyframes } from "@emotion/react";

const pulse = keyframes`
0% { opacity: 1; }
50% { opacity: 0.6; }
100% { opacity: 1; }
`;
export const useMonitorStatistics = () => {
  const [metricsStatistics, setMetricsStatistics] = useState({});
  const { getApi,postApi } = useApi();
  const [updateNeeded, setUpdateNeeded] = useState(false);
  const fetchResults = useCallback(() => {
    getApi(`/monitor/daily`).then((response) => {
      const processedData = response.data.reduce((acc, metric) => {
        const category = metric.category || "Uncategorized";
  
        // Ensure category exists in accumulator
        if (!acc[category]) acc[category] = [];
  
        // Collect all tenants with their respective states
        const tenants = [
          ...(metric.warning_tenants || []).map((t) => ({ ...t, state: "warning" })),
          ...(metric.error_tenants || []).map((t) => ({ ...t, state: "error" })),
          ...(metric.success_tenants || []).map((t) => ({ ...t, state: "success" })),
          ...(metric.snoozed_tenants || []).map((t) => ({ ...t, state: "snoozed" })),
        ];
  
        // Sort tenants: Warning > Error > Success > Snoozed
        const sortedTenants = tenants.sort((a, b) => {
          const priority = { warning: 3, error: 2, success: 1, snoozed: 0 };
          return priority[b.state] - priority[a.state];
        });
  
        // Add processed metric with state counts (ONLY ONCE)
        acc[category].push({
          ...metric,
          sortedTenants,
          warningCount: metric.warning_tenants?.length || 0,
          errorCount: metric.error_tenants?.length || 0,
          successCount: metric.success_tenants?.length || 0,
          snoozedCount: metric.snoozed_tenants?.length || 0,
        });
  
        return acc;
      }, {});
  
      setMetricsStatistics(processedData);
    });
  }, [getApi]);

  const submitAction = useCallback(
    (action, selected) => {
      postApi(
        `/monitor/job/`,
        { action: action, metrics: selected },
      ).then(() => {
      });
    },
    [postApi]
  );

  const RefreshButton = useCallback(() => {
    return (
      <Button
        variant="contained"
        onClick={fetchResults}
        sx={{
          animation: updateNeeded ? `${pulse} 1.5s infinite` : "none",
        }}
      >
        Refresh
      </Button>
    );
  }, [fetchResults, updateNeeded]);
  useEffect(() => {
    fetchResults();
  }, [fetchResults]);
  return { metricsStatistics,submitAction,RefreshButton };
};

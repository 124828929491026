import { Table, TableBody, TableRow, Skeleton, TableCell, Box } from "@mui/material";
import React from "react";

export default function loading() {}

export function LoadingTable() {
  return (
    <>
      <Table>
        <TableBody>
          {[...Array(10)].map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Skeleton animation="wave" variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export function LoadingSkeleton() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2, // Spacing between accordions
      }}
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            border: "1px solid lightgray",
            borderRadius: "8px",
            padding: 2,
          }}
        >
          {/* Title skeleton */}
          <Skeleton variant="text" height={30} width="70%" />

          {/* Body skeleton */}
          <Skeleton variant="rectangular" height={50} width="100%" />
        </Box>
      ))}
    </Box>
  );
}

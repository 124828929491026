import { useState } from "react";
import MetricsStatisticsTable from "../../components/customers/MetricStatisticsTable";
import { LoadingTable } from "../../components/system/layout/LoadingElements";
import { PageTitle } from "../../components/system/layout/PageElements";
import { useMonitorStatistics } from "../../hooks/customers/useMonitorStatistics";
import MonitorMetricActionsDropdown from "../../components/customers/tenantProfile/MonitorMetricActionsDropdown";
import { useNotification } from "../../components/system/NotificationCenter";
import { Button, Grid } from "@mui/material";

function MonitorMetrics() {
  const { metricsStatistics, submitAction,RefreshButton } = useMonitorStatistics();
  const { sendNotification } = useNotification();
  const [selected, setSelected] = useState([]);
  const toggleSelect = (id) => {
    setSelected(
      selected.includes(id)
        ? selected.filter((i) => i !== id)
        : [...selected, id]
    );
  };
  const selectAll = () => {
    setSelected(metricsStatistics.map((m) => m.metricId));
  };
  const deselectAll = () => {
    setSelected([]);
  };
  const handleSubmitAction = (action)=>{
    submitAction(action, selected);
    sendNotification(
      "Request submitted, refresh within the next minute to see fresh results.",
      "success"
    );
    deselectAll();
  }
  return (
    <>
      <PageTitle>Monitoring</PageTitle>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <MonitorMetricActionsDropdown
            submitAction={handleSubmitAction}
            enabled={selected.length > 0}
          />
        </Grid>

        <Grid item sx={{ display: "flex", gap: 2, paddingY: 2 }}>
          {selected.length > 0 && (
            <Button variant="contained" onClick={deselectAll}>
              Deselect All
            </Button>
          )}
          <Button variant="contained" onClick={selectAll}>
            Select All
          </Button>

          <RefreshButton />
        </Grid>
      </Grid>
      {metricsStatistics ? (
        <MetricsStatisticsTable metrics={metricsStatistics} submitAction={submitAction} selectedList={selected} toggleSelect={toggleSelect} />
      ) : (
        <LoadingTable />
      )}
    </>
  );
}

export default MonitorMetrics;

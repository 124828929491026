import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { MetricIcon } from "./MonitorMetric";
const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);
function MonitorMetricResults({ results }) {
  const [order, setOrder] = useState("desc"); // Sorting order
  const [orderBy, setOrderBy] = useState("timestamp"); // Default sorting column

  // Sorting logic
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  // Function to sort the data
  const sortedResults = [...results].sort((a, b) => {
    if (orderBy === "timestamp") {
      return order === "asc"
        ? new Date(a.timestamp) - new Date(b.timestamp)
        : new Date(b.timestamp) - new Date(a.timestamp);
    } else if (orderBy === "state") {
      return order === "asc"
        ? (a.state || "").localeCompare(b.state || "")
        : (b.state || "").localeCompare(a.state || "");
    } else {
      return 0; // No sorting for other columns
    }
  });

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
        <TableRow>
            <TableCell sx={{ width: "1px", padding: "4px" }} /> {/* Icon Column */}
            <TableCell>
              <TableSortLabel
                active={orderBy === "state"}
                direction={orderBy === "state" ? order : "asc"}
                onClick={() => handleSortRequest("state")}
              >
                State
              </TableSortLabel>
            </TableCell>
            <TableCell>Value</TableCell> {/* Value column is NOT sortable */}
            <TableCell>
              <TableSortLabel
                active={orderBy === "timestamp"}
                direction={orderBy === "timestamp" ? order : "asc"}
                onClick={() => handleSortRequest("timestamp")}
              >
                Timestamp
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedResults.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ width: "1px", padding: "4px" }}>
                <MetricIcon state={row.state} />
              </TableCell>
              {/* Icon Column */}
              <TableCell>{capitalize(row.state)}</TableCell>
              <TableCell>{row.value ? row.value : "-"}</TableCell>
              <TableCell>{new Date(row.timestamp).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MonitorMetricResults;

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useState } from "react";
import { useNotification } from "../../system/NotificationCenter";
function MonitorMetricActionsDropdown({ submitAction, enabled }) {
  const [selectedAction, setSelectedAction] = useState("");
  const handleChange = (event) => {
    const action = event.target.value;
    setSelectedAction(action);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <FormControl size="medium" sx={{ minWidth: 350 }}>
        <InputLabel htmlFor="actions-label">Actions</InputLabel>
        <Select
          id="actions-label"
          value={selectedAction}
          onChange={handleChange}
          label="Actions"
        >
          <MenuItem value="check">Check Selected</MenuItem>
          <MenuItem value="remediate">Remediate Selected</MenuItem>
          <MenuItem value="snooze">Snooze Selected</MenuItem>
          <MenuItem value="unsnooze">Reactivate Selected</MenuItem>
          <MenuItem value="enableautofix">Enable Autofix on Selected</MenuItem>
          <MenuItem value="disableautofix">Disable Autofix on Selected</MenuItem>
          <MenuItem value="recalculate">Recalculate summary</MenuItem>
        </Select>
      </FormControl>

      {/* Send Button - Aligned Next to Select */}
      <IconButton
        onClick={() => submitAction(selectedAction)}
        disabled={selectedAction === "recalculate" ? false : (!selectedAction || !enabled) } // Disable if no action is selected
        color="primary"
      >
        <Send />
      </IconButton>
    </Box>
  );
}

export default MonitorMetricActionsDropdown;

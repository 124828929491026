import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
  Checkbox,
  Chip,
} from "@mui/material";
import {
  Bedtime,
  CheckCircleOutline,
  ExpandMore,
  HighlightOffOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import moment from "moment";
import { blue, green, orange, red } from "@mui/material/colors";
import MonitorMetricResults from "./MonitorMetricResults";
const formattedDate = (date) => {
  return moment(date).isValid() ? moment(date).fromNow() : "Never";
};
function MonitorMetric({
  metric,
  snooze,
  submitCheck,
  selected,
  toggleSelect,
}) {
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container alignItems="center" justifyContent="space-between">
            {toggleSelect && <Grid item>
              <Checkbox
                checked={selected}
                onChange={(event) => {
                  event.stopPropagation();
                  toggleSelect(metric.metricId);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            </Grid>}
            {/* Icon and Name aligned to the left */}
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
            >
              <MetricIcon state={metric.state} />
              <Typography variant="h6" sx={{ ml: 1 }}>
                {metric.name}
              </Typography>
            </Grid>

            <Grid item sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "flex-end" }}>
              {metric.autofix && <Chip label="autofix" size="small" color="secondary"/>}
              {metric.remediation && <Chip label="remediation" size="small" color="primary"/>}
              <Typography variant="h6">
                {formattedDate(metric.latest)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Grid container sx={{ justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => submitCheck(metric.metricId)}
            >
              Check Now
            </Button>
            <Button variant="outlined">Snooze</Button>
          </Grid> */}
          {/* Only render if there are results */}
          {metric.results.length > 0 && (
            <MonitorMetricResults results={metric.results} />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default MonitorMetric;

export const MetricIcon = ({ state }) => {
  switch (state) {
    case "success":
      return <CheckCircleOutline sx={{ color: green[700] }} />;
    case "warning":
      return <WarningAmberRounded sx={{ color: orange[700] }} />;
    case "error":
      return <HighlightOffOutlined sx={{ color: red[700] }} />;
    case "snoozed":
      return <Bedtime sx={{ color: blue[700] }} />;
    default:
      return null;
  }
};
